import { customNotification } from "src/utils/CommomFunction";
import { Modal } from 'antd';
import axios from "axios";
import { setLocalStorage } from "utils/CommomFunction";
import Reactotron from "reactotron-react-js";


const END_POINT =  `https://fnb.uat.test88.info/graphql`;

const { info } = Modal;

export * from "./callApi";

export const callApi = async (query, variables, isLogin = true) => {
  const accessToken = localStorage.getItem("access_token") || "";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  if (!accessToken && isLogin) {
    localStorage.clear();
    window.location.href = `${window.location.origin}/login`;
  }

  try {
    const result = await axios.post(END_POINT, { query, variables }, { headers });
    Reactotron.apisauce(result);
    if (result.data?.errors && result.data?.errors[0]?.message) {
      if (result.data?.errors[0]?.message && result.data?.errors[0]?.extensions?.category == "graphql-authorization") {
        const popState = localStorage.getItem("popState") || '1';
        if (popState === '1') {
          setLocalStorage("popState", 0);
        };
        let timer = setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 3000);

        return info({
          icon: <></>,
          content: <span>Phiên đăng nhập đã hết hạn.</span>,
          onOk() {
            localStorage.clear();
            window.location.reload();
            clearTimeout(timer);
          },
        });
        // default:
        //   notification('error',result.data?.errors[0]?.message )
      }
    }
    return result.data;
  } catch (e) {
    if (e?.message && e?.message?.includes("Network Error")) {
      customNotification("error", "Hãy kiểm tra lại đường truyền internet");
    }
    customNotification("error", "Có lỗi xảy ra trong quá trình xử lý!");
  }
  return true;
};
