import { RightCircleOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import ButtonTime from "../Button/ButtonTime";
import "./index.scss";
import iconShop from "../../assets/shop.png";
import iconMoto from "../../assets/motocycle-small.png";
import moment from "moment";
import { formatPrice, convertOrderType } from "src/utils/CommomFunction";
import { Draggable } from "react-beautiful-dnd";
import { statusConvertData } from "../../utils/CommomFunction";
import { isEmpty } from "lodash";

function Order(props) {
  const [countdownDate, setCountdownDate] = useState(new Date());
  const [isNotResponse, setNotResponse] = useState(false);

  const {
    order, openModal, handleSubmitBom, id, playSound = () => { },
    saveOrderListNotResponse = () => { },
    orderListNotResponse = [],
    playOrderNOtResponseAgain = () => { },
    turnOffAppSound,
  } = props;

  const findOrderOffSound = orderListNotResponse.find(obj => obj?.id == order?.id);
  const isOffSound = findOrderOffSound && findOrderOffSound?.isOffSound ? true : false;
  const timePause = findOrderOffSound && findOrderOffSound?.timePause ? findOrderOffSound?.timePause : null;

  const { status, notification_status, } = order;

  const timeToEnd = moment(order?.created_at)
    .add(Number(order?.delivery_time || 30), "m")
    .format("hh:mm A");

  const showDetail = useRef(true);

  const handleClickOrder = () => {
    // callApiGetTurnOffAppSound();
    if (showDetail.current) {
      openModal(order?.status, order);
    } else {
      showDetail.current = true;
    };
  };

  useEffect(() => {
    let countDownInterVal = setInterval(
      () => setCountdownDate(new Date()),
      1000,
    );
    return () => clearInterval(countDownInterVal);
  }, []);

  const timeOver = moment.utc(order?.created_at).add(order?.notification_time, 'minutes').format("MM/DD/YYYY hh:mm:ss A");
  const timeOverLastColumn = moment.utc(order?.created_at).add(30, 'minutes').format("MM/DD/YYYY hh:mm:ss A");

  const timePauseOrder = timePause ? timePause + parseInt(turnOffAppSound) * 60000 : 0;

  let distanceToTimePause = timePauseOrder - Date.parse(new Date(countdownDate));

  const checkNotResponseVolume = () => {
    const tmpOrder = orderListNotResponse.find(obj => obj?.id == order?.id);
    if (!tmpOrder) {
      playSound();
    } else if (!tmpOrder?.isOffSound) {
      playSound();
    }
    saveOrderListNotResponse({ type: "add", order });
    setNotResponse(true);
  };

  useEffect(() => {
    if (distanceToTimePause < 0 && isOffSound) {
      playOrderNOtResponseAgain({ order });
    }
  }, [distanceToTimePause])

  useEffect(() => {
    if (status == "received" || status == "cooking" || status == "ready_to_ship") {
      if (moment(timeOver).isBefore(moment(countdownDate)) &&
        (parseInt(statusConvertData[status]) <= parseInt(notification_status))
      ) {
        if (status !== "pending") {
          checkNotResponseVolume();
        }
      } else {
        saveOrderListNotResponse({ type: "remove", order });
      }
    }
  }, [countdownDate]);

  return (
    <Draggable draggableId={order?.order_number?.toString()} key={id} index={order?.id}>
      {(provided, snapshot) => (
        <span
          key={id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={handleClickOrder}
        >
          <div className={`order-item ${(isNotResponse && !isOffSound) ? "notResponse" : ""}`}>
            {order?.assign_from?.name && order?.status === "pending" && (
              <div className="order-item-moved">
                <RightCircleOutlined />
                {`Được chuyển từ ${order?.assign_from?.name}`}
              </div>
            )}
            <div className="order-item-box" style={{
              // height: order?.vouchers_applied?.length > 0 ? 'auto' : '145px'
            }}>
              <div className="order-item-header">
                <h3>
                  {`#${order?.order_number}`}
                  <small> | {order?.id}</small>
                </h3>
                <h5>{formatPrice(order?.grand_total)}đ</h5>
              </div>
              {
                order?.external_order_id &&
                <p style={{ fontWeight: "400", fontSize: 13 }}>
                  {`Mã đơn ${(order?.order_type == "CALL CENTER" || order?.order_type == "STORE") ? "Hoa Sao" : "zalo"}: `}
                  #{(order?.order_type == "CALL CENTER" || order?.order_type == "STORE") ? order?.external_order_id :
                    order?.external_order_id?.toString()?.slice(order?.external_order_id?.toString().length - 9)}{" "}
                </p>
              }
              <div className="order-item-desc">
                <p>{`Nhận đơn lúc ${moment(order?.created_at).format(
                  "hh:mm A"
                )}`}</p>
                <p>{order?.qty} món</p>
              </div>
              <div className={'border-y'}>
                <span>Kênh đặt hàng</span>
                <span
                  className={order?.order_type === 'App' ? 'yellow' : order?.order_type === 'Zalo OSC' ? 'zalo' : 'green'}
                >
                  {convertOrderType(order?.order_type)}
                </span>
              </div>
              <div className="order-item-time">
                {order?.status === "bom_request" ? (
                  <>
                    <div className="order-item-desc">
                      <button className="button btn-order-cancel">Bom</button>
                    </div>
                    <button
                      className="button btn-order-confirm"
                      onClick={() => {
                        showDetail.current = false;
                        handleSubmitBom(order);
                      }}
                    >
                      XÁC NHẬN
                    </button>
                  </>
                ) : (
                  <>
                    {(
                      (order?.shipping_method === "Giao hàng tận nơi") ||
                      (!order?.shipping_method)) &&
                      (
                        <>
                          <div className="order-item-desc">
                            <img src={iconMoto} alt=""></img>
                            <p>Giao tận nơi lúc: {timeToEnd}</p>
                          </div>
                          <ButtonTime dataOrder={order} />
                        </>
                      )}
                    {order?.shipping_method === "Nhận tại cửa hàng" && (
                      <>
                        <div className="order-item-desc">
                          {/* <ShopOutlined className="order-shop" /> */}
                          <img src={iconShop} alt=""></img>
                          <p>Hẹn lấy tại cửa hàng</p>
                        </div>
                        <ButtonTime dataOrder={order} />
                      </>
                    )}
                  </>
                )}
              </div>

              {order?.vouchers_applied?.length > 0 &&
                <div className="order-item-voucher">
                  <ul className="text-right" style={{
                    marginLeft: '2.5rem',
                    marginBottom: 0,
                    color: "#0A8D87",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}>
                    {order?.vouchers_applied?.map((item, index) => (
                      <li key={index}>
                        <p>
                          {`${item?.code} ${!isEmpty(item?.note) ? `(${item?.note})` : ""}`}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              }
            </div>
          </div>
        </span>
      )}
    </Draggable>
  );
}

export default Order;
