import React from 'react';
import { message, Tag } from 'antd';
import moment from 'moment';

export const setLocalStorage = (key, value) => localStorage.setItem(
  key,
  typeof value === 'object' ? JSON.stringify(value) : value,
);

export const getLocalStorage = (key) => localStorage.getItem(key);

export const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

export const recursionOpenKeys = (items, openKeys, pathName) => {
  items.forEach((item) => {
    if (item.children && pathName.indexOf(item.url) !== -1) {
      openKeys.push(item.url);
      recursionOpenKeys(item.children, openKeys, pathName);
    }
  });
  return openKeys;
};

export const customNotification = (type, messageText, customClass = '') => {
  try {
    message.destroy();
  } catch (e) {
    console.log(e);
  }
  let config = {
    content: messageText,
  }
  if (customClass.length > 0) {
    config = { ...config, className: customClass, icon: <></> };
  }

  message[type](config);
};

export const numberWithDots = (price) => {
  const regex = new RegExp('^[0,]+(?!$)', 'g');
  return price &&
    price
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(regex, "");
}

export const getPathPosition = (string, subString, index) => string.split(subString, index)
  .join(subString).length;

export const onClickRow = (record, setState) => ({
  onClick: () => {
    setState(record.id);
  },
});

export const setRowClassName = (record, state) => (record.id === state ? 'clickRowStyl' : '');

export const checkTimeErrorSearch = (fromDate, toDate) => {
  const checkTimeError = moment(fromDate, 'DD/MM/YYYY HH:mm:ss') > moment(toDate, 'DD/MM/YYYY HH:mm:ss');
  if (checkTimeError) {
    customNotification('error', 'Từ ngày không được lớn hơn đến ngày');
  }
  return checkTimeError;
};

export const formatPrice = (price) => {

  if (price < 1) return "0";
  if (price === '0đ') return '0đ';
  const regex = new RegExp('^[0,]+(?!$)', 'g');
  return price && price.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .replace(regex, '');
}

export const formatMoney = (money) => {
  const regex = new RegExp('^[0,]+(?!$)', 'g');
  if (money && money.toString().indexOf('.') === -1) {
    return formatPrice(money)
  } else {
    return money && money.toString()
      .replace(/\B(\d)(?=(\d{3})+\.)/g, '$1,')
      .replace(regex, '');
  }
}
export const deepCopy = arr => JSON.parse(JSON.stringify(arr));

export const SESSION = {
  save: (key, value) => window.sessionStorage.setItem(key, JSON.stringify(value)),
  remove: (key) => window.sessionStorage.removeItem(key),
  fetch: (key) => JSON.parse(window.sessionStorage.getItem(key)),
}

export const LOCAL = {
  save: (key, value) => window.localStorage.setItem(key, JSON.stringify(value)),
  remove: (key) => window.localStorage.removeItem(key),
  fetch: (key) => JSON.parse(window.localStorage.getItem(key)),
}

export const renderTotalRows = (total, range) => (
  <span className="red-bold">
    <span>{range[0]}-{range[1]}</span> trên tổng số <span>{total < 10 ? `0${total}` : total}</span> bản ghi
  </span>
);

export const addKeyForItem = (list, pagination) => {
  if (list && list.length > 0) {
    const result = list.map((item, idx) => {
      item.key = (pagination.current - 1) * pagination.pageSize + idx + 1;
      return item;
    })
    return result
  }
  return []
}

export const convertCode = (code, list) => {
  if (list.length === 0) {
    return code;
  }
  const match = list.find(item => {
    const pattern = item.code ? item.code : item.id;
    return pattern == code;
  });
  if (match) {
    const { color, value, description } = match;
    if (color) {
      return <Tag color={color}>{value ? value : description}</Tag>;
    }
    return value ? value : description;
  }
  return code;
};

export const renderCustomerTypeCode = (customerTypeCode) => {
  const LIST_CUSTOMER_TYPE = JSON.parse(getLocalStorage('LIST_TYPE_CUSTOMER'));
  const renderTypeCustomer = LIST_CUSTOMER_TYPE.find(item => item.code === customerTypeCode);
  if (renderTypeCustomer) {
    return renderTypeCustomer.name
  }
  return customerTypeCode
}

export const renderCustomerVIP = (vip) => {
  switch (vip) {
    case 1:
      return 'VIP';
    case 2:
      return 'Phổ thông'
  }
}

export const formatDateCustomer = (date) => {
  if (date && date.length > 0) {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    return `${day}/${month}/${year}`
  }
  return ''
}

export const mapBranchName = (code, listBranch = []) => {
  const branch = listBranch.find(item => item.code === code);
  if (branch) {
    return `${code} - ${branch.viFullName}`
  }
  return code
}

export const mapTypeDayCode = (code, listDay = []) => {
  const typeDay = listDay.find(item => item.code === code);
  if (typeDay) {
    return `${typeDay.description}`
  }
  return code
}

function isObject(objValue) {
  return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}

export const updateAccountInfo = (origin, reliable) => {
  const trueData = deepCopy(origin);
  const keys = Object.keys(origin);
  keys.forEach(item => {
    if (item !== 'status' && item !== 'id') {
      if (isObject(trueData[item])) {
        trueData[item] = updateAccountInfo(trueData[item], reliable);
      } else {
        const truePiece = reliable[item] ? reliable[item] : (reliable.cifGeneral[item] ? reliable.cifGeneral[item] : null);
        if (truePiece && trueData[item] != truePiece) {
          trueData[item] = truePiece;
        }
      }
    }
  });
  trueData.currentVip = reliable.cifGeneral.vip;
  trueData.accountStatus = reliable.status;
  return trueData;
}

export const renderExemptionRate = (record) => {
  if (record.typeFee === 1) {
    return `${formatPrice(record.valueFee)} %`;
  } else {
    return `${formatPrice(record.valueFee)}`;
  }
}
export const convertCodeProvince = (id) => {
  if (id) {
    const LIST_PROVINCE = JSON.parse(localStorage.getItem("LIST_PROVINCE")) || []
    for (let i = 0; i < LIST_PROVINCE.length; i++) {
      if (LIST_PROVINCE[i].id === id) {
        return `${LIST_PROVINCE[i].id} - ${LIST_PROVINCE[i].name}`
      }
    }
  }
}

export const transferStartDate = (startDate) => {
  if (moment(startDate).startOf('day') - moment().startOf('day') === 0) {
    return moment(startDate)
  } else {
    return moment(startDate).startOf('day')
  }
}

export const removeDuplicateObject = (array, dupField) => {
  const noDupArray = array.filter((value, index, self) =>
    index === self.findIndex((t) => (
      dupField.every(item => t[item] === value[item])
    ))
  )
  return noDupArray;
}

export const statusConvertData = {
  pending: '1',
  received: '2',
  cooking: '3',
  ready_to_ship: '4',
  shipping: '5',
  arrived: '5',
  bom: '5'
};

export const convertOrderType = (order_type) => {

  let value = order_type;

  if (!order_type) {
    value = "Website"
  };

  if (order_type == "App") {
    value = "Ứng dụng"
  }

  // if(order_type == "CALL CENTER" || order_type == "STORE"){
  //   value = "Hoa Sao"
  // }
  return value;
}

export const translatePaymentMethod = (payment_method) => {
  let text = payment_method;
  switch (payment_method) {
    case "Cash On Delivery":
      text = "Thanh Toán Tiền Mặt"
      break;

    case "Wallet":
    case "eWallet":
      text = "Ví điện tử";
      break;

    default:
      break;
  };

  return text;
};

export const toCommas = (value) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};


export function wait(timeout = 500) {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
}

export const addDecodeIdOrderList = (orderList = []) => {

  const tempArr = [...orderList].map(obj => ({
    ...obj,
    decodeId: obj?.id
  }));
  for (var i = 0; i < tempArr.length; i++) {
    delete tempArr[i].id;
  };
  for (var i = 0; i < tempArr.length; i++) {
    var order = tempArr[i];
    order.id = parseInt(atob(order.decodeId));
  };

  return tempArr;
};
