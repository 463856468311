import { callApi } from "..";

export const apiGetListOrder = () =>
  callApi(
    `{
      merchantOrders(
        sort: {
          sort_field: NUMBER,
          sort_direction: DESC, 
        },
        filter: {},
        pageSize: 30,
        currentPage: 1
      ){
        page_info {
            page_size
            current_page
        }
        total_count
        items {
          order_number
          id
          created_at
          grand_total
          total {
            grand_total {
                value
                currency
            }
          }
          status
          table
        }
      }
 
  }`,
    null
  );

export const apiGetListOrderRefund = () =>
  callApi(
    `{
    merchantOrderHistory(flagRefund: 1) {
      orders {
        address
        created_at
        delivery_time
        firstname
        grand_total
        id
        lastname
        order_number
        payment_method
        qty
        restaurant_id
        shipping_method
        flag_refund
        status
        use_plastic
        shipper_info {
          first_name
          last_name
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }`,
    null
  );

export const apiMoveOrderToAnother = (data) =>
  callApi(
    `mutation ($orderId:Int!, $restaurantId:Int!, $reason:String!) {
    moveOrder(orderId:$orderId, restaurantId:$restaurantId, reason:$reason) {
      result
    }
  }`,
    data
  );

export const apiReciveOrder = (data) =>
  callApi(
    `mutation($id: Int!){
      receivedOrder(id: $id){
      result
    }
  }`,
    data
  );

export const apiCookingOrder = (data) =>
  callApi(
    `mutation($id: Int!){
      cookingOrder(id: $id){
      result
    }
  }`,
    data
  );

export const apiCancelOrder = (data) =>
  callApi(
    `mutation($orderId: Int!){
    cancelOrder(id:$orderId){
      result
    }
  }`,
    data
  );

export const apiGetAllStoreInTheArea = () =>
  callApi(
    `{
      getStoreJsonData {
        url
        version
      }
    }`,
    null
  );

export const apiAcceptShipping = (data) =>
  callApi(
    `mutation (
    $id: Int!
    $type: Int!
  ){
    merchantAcceptShipping (
      id:  $id
      type: $type
    ) {
      result
    }
  }`,
    data
  );

export const apiMerchantReadyToShip = (data) =>
  callApi(
    `mutation($id: Int!){
      readyToShipOrder(id: $id){
      result
    }
  }`,
    data
  );

export const apiGetOrderStatusHistory = (data) =>
  callApi(
    `query($orderId: Int!){
    getOrderStatusHistory(
        orderId: $orderId
    ) {
        list {
            created_at
            status
            comment
        }
    }
}`,
    data
  );

export const apiGetDetailOrder = (data) =>
  callApi(
    `query($id: Int) {
      orderDetail(id:$id) {
        id
        order_number
        created_at
        updated_at
        grand_total
        status
        flag_refund
        payment_method
        payment_method_code
        firstname
        lastname
        phone
        address
        customer_comment
        items {
            name
            qty
            price
            options {
                name
                qty
                price
            }
        }
        discount {
            amount {
                currency
                value
            }
            label
        }
        shipping_method
        use_plastic
        note
        customer_phone
        assign_from
        assign_reason
        shipping_amount
      }
  }`,
    data
  );
export const apiBomOrder = (data) =>
  callApi(
    `mutation($id:Int!) {
      bomOrder(id:$id){
         result
       }
     }`,
    data
  );

export const apiCompleteOrder = (data) =>
  callApi(
    `mutation($id:Int!) {
      completeOrder(id:$id){
         result
       }
     }`,
    data
  );

export const apiShipping = (data) =>
  callApi(
    `mutation($id:Int!) {
      shippingOrder(id:$id){
        result
      }
    }`,
    data
  );

export const apiApproveRefund = (data) =>
  callApi(
    `mutation($id:Int!) {
      approveRefund(orderId:$id){
        result
      }
    }`,
    data
  );

export const apiGetOrderHistory = () =>
  callApi(
    `query  {
      merchantOrderHistory(currentPage: 1, pageSize: 20){
          orders {
              id
              order_number
              created_at
              updated_at
              grand_total
              status
              flag_refund
              payment_method
              payment_method_code
              firstname
              lastname
              phone
              address
              customer_comment
              discount {
                  amount {
                      currency
                      value
                  }
                  label
              }
              items {
                  name
                  qty
                  price
                  options {
                      name
                      qty
                      price
                  }
              }
              shipping_method
              use_plastic
              note
              customer_phone
              assign_from
              assign_reason
              shipping_amount
          }
          page_info {
              page_size
              current_page
              total_pages
          }
      }
  }`
  );

export const apiGetReasonCanel = () =>
  callApi(
    `query {
      storeConfig {
          reasons_cancel
      }
    }`
  );

export const apiGetTurnOffAppSound = () =>
  callApi(
    `query{
      storeConfig {
          turn_off_app_sound
      }
    }`
  );

export const apiGetTimeReloadOrder = () =>
  callApi(
    `query{
      storeConfig {
        time_reload_order
      }
    }`
  );

export const apiNotRefund = (data) =>
  callApi(
    ` mutation ($id: Int!, $shipperId: Int!) {
      noRefund(orderId: $id, shipperId: $shipperId) {
        result
      }
    }`,
    data
  );

export const apiPushSocketIdToServer = (data) =>
  callApi(
    `   mutation ($socketId: String!) {
      receiveSocketId(socketId: $socketId) {
        result
      }
    }`,
    data
  );
